import request from '@/api/request.js'

//获取楼栋列表
export function getLouDongListSelectFn (data){
  return request({
    url:'/village/common/building_select',
    method:'get',
    params:data
  })
}
//获取单元
export function getDanYuanSelectFn (data){
  return request({
    url:'/village/common/unit_select',
    method:'get',
    params:data
  })
}
//获取单元
export function getZhuFangSelectFn (data){
  return request({
    url:'/village/common/house_select',
    method:'get',
    params:data
  })
}
