<template>
	<div class="container">
		<div class="title">
      <div class="inner_title">编辑住房</div>
    </div>
		<div>
      <div class="item-box">
				<span class="item-left">楼栋：</span>
				<el-select v-model="louDongId" @change="getDanYuanList" placeholder="请选择">
          <el-option
            v-for="item in louDongList"
            :key="item.id"
            :label="item.building_number"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
			<div class="item-box">
				<span class="item-left">单元：</span>
				<el-select class="rantHouseName" v-model="unit_id" filterable placeholder="请选择">
					<el-option 
            v-for="item in danYuanList" 
            :key="item.id" 
            :label="item.unit_number" 
            :value="item.id">
          </el-option>
				</el-select>
			</div>
			<div class="item-box">
				<span class="item-left">房号：</span>
				<el-input class="rantHouseName" v-model="house_number"   placeholder="请输入"></el-input>
        <span class="house-red-tips">房屋号只能输入数字</span>
			</div>
			<div class="sub-box">
				<el-button class="submitBtn" type="primary" plain @click="submit">提交</el-button>
			</div>
		</div>
	</div>
</template>
<script>
  import {getZhuFangDetailsFn,editZhuFangFn} from '@/api/zhuFang.js'
  import {getLouDongListSelectFn,getDanYuanSelectFn} from '@/api/select.js'
	export default {
		props: [],
		data() {
			return {
        id:'',

        louDongList:[],
        louDongId:'',

        danYuanList:[],
        unit_id:'',
        house_number:'',
			};
		},
    created() {
      this.id = this.$route.query.id
      this.page = this.$route.query.page;
			this.last_page = this.$route.query.last_page;
		},
		mounted() {
      this.getZhuFangDetail()
		},
		methods: {
      //详情
			getZhuFangDetail() {
				getZhuFangDetailsFn({
					id: this.id,
          api_token: localStorage.getItem('token1'),
				}).then(res => {
          this.louDongId = res.datas.building_id;
          this.unit_id = res.datas.unit_id;
          this.house_number = res.datas.house_number
          this.getLouDongList()
          this.getDanYuanList()
				});
			},
      //获取单元列表
      getDanYuanList(){
        this.loading = true;
				getDanYuanSelectFn({
          api_token: localStorage.getItem('token1'),
          building_id:this.louDongId
				}).then(res => {
					this.danYuanList = res.datas;
					this.loading = false;
				})
      },
      //获取楼栋列表
      getLouDongList() {
				this.loading = true;
				getLouDongListSelectFn({
          api_token: localStorage.getItem('token1'),
				}).then(res => {
					this.louDongList = res.datas;
					this.loading = false;
				})
			},
			//提交
			submit() {
        editZhuFangFn({
          api_token : localStorage.getItem('token1'),
          id:this.id,
					unit_id:this.unit_id,
          house_number:this.house_number,
				}).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
								this.$router.push({
									path: "/zhuFangList",
									query: {
										page: this.page,
										last_page: this.last_page
									}
								});
							}
						});
					}
				});
			},
		},
	};
</script>
<style scoped>
	@import url("../../../style/detail.css");
</style>
